// @flow

import React from "react";
import {
    withPrivateRoute,
    Layout,
    AdminUserManagementLayout,
    ExternalPersons,
} from "@containers";

/**
 * Admin Gebruikers Beheer - Interne Personen
 */
const AdminExternalPersons = (props: *) => {
    return (
        <Layout {...props}>
            <AdminUserManagementLayout>
                <ExternalPersons id="external-persons" />
            </AdminUserManagementLayout>
        </Layout>
    );
};

export default withPrivateRoute(AdminExternalPersons);
